<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'
import ReviewCard from '../../../Components/UI/ReviewCard.vue'

defineProps<{
    reviews: Array<any>
}>()

const { t } = useI18n()
</script>

<template>
    <section class="pt-24 pb-16 bg-white">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="sm:flex sm:items-baseline sm:justify-between">
                <h2 class="text-2xl font-bold tracking-tight text-gray-900">{{ t('pages.home.latest-reviews.title') }}</h2>
                <Link :href="route('reviews', { type: 'shop' })" class="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block">
                {{ t('pages.home.latest-reviews.all-reviews') }}
                <span aria-hidden="true"> &rarr;</span>
                </Link>
            </div>

            <div class="flow-root mx-auto mt-6">
                <div class="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
                    <div v-for="review in reviews" :key="review.id" class="pt-8 sm:inline-block sm:w-full sm:px-4">
                        <ReviewCard :review="review" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
